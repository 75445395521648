@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --crunchyroll: rgb(232, 126, 21);
  --crunchyrollBright: #FF9900;
  --discord: #2C2F33;
  --discordDark: #23272A;
  --cardbg: #23272A;
  --soNearlyBlack: #0E191E;
  --crBlue: #2DBDBB;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slow-grow {
  transition: all .3s ease-in-out;
}

.item-hide {
  height: 0;
  max-height: 0;
}

.item-show {
  height: 16rem;
  max-height: 16rem;
}

.card-small {
  width: 10rem;
  max-width: 10rem;
}

.card-big {
  width: 31rem;
  max-width: 31rem;
}

.hide-next-siblings + div,
.hide-next-siblings + div + div {
  transition: visibility .2s linear, opacity .2s linear;
  visibility: hidden;
  opacity: 0;
}

.border-b-3 {
   border-bottom-width: 3px;
}

.border-cr-blue {
  border-color: var(--crBlue);
}

.border-so-nearly-black {
  border-color: var(--soNearlyBlack);
}

.bg-cr-blue {
  background: var(--crBlue);
}

.bg-gradient-about {
  background: linear-gradient(#000, #213944)
}

.bg-gradient-about-quick-dark {
  background: linear-gradient(#000, #0E191E);
}

.bg-slate-gray-dark {
  background: #213944;
}

.bg-discord {
  background: var(--discord);
}

.bg-discord-dark {
  background: var(--discordDark);
}

.bg-so-nearly-black {
  background: var(--soNearlyBlack);
}

.text-discord {
  color: var(--discord);
}

.text-discord-dark {
  color: var(--discordDark);
}

.text-cr-blue {
  color: var(--crBlue);
}

.shadow-embed {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

.shadow-sharp {
    box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.9);
}

.shadow-small-sharp {
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
}

.border-crunchy {
  border-color: var(--crunchyroll);
}

.text-crunchy {
  color: var(--crunchyrollBright);
}

.bg-crunchy {
  background: var(--crunchyroll);
}

.hover\:text-crunchy:hover {
  color: var(--crunchyrollBright);
}

.loader-slide-from-above-1 {
  animation: from-above 0.4s linear;
}

.loader-slide-from-left-1 {
  animation: from-left 0.2s ease-in-out;
}

.loader-slide-from-left-1\/2 {
  animation: from-left 0.4s ease-in-out;
}

.loader-slide-from-left-2 {
  animation: from-left 0.6s ease-in-out;
}

.loader-slide-from-right-1 {
  animation: from-right 0.4s ease-in-out;
}

.loader-slide-from-right-1\/2 {
  animation: from-right 0.5s ease-in-out;
}

.loader-slide-from-right-2 {
  animation: from-right 0.8s ease-in-out;
}

@keyframes from-above {
  from {
    transform: translateY(-600px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes from-left {
  from {
    transform: translateX(-1000px);
  }
  to {
    transform: translateX(0);
  }
}


@keyframes from-right {
  from {
    transform: translateX(1000px);
  }
  to {
    transform: translateX(0);
  }
}


.bg-card {
  background: var(--cardbg);
}

.animate-spin {
  animation: spin 1s linear infinite reverse;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}